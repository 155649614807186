import React, { useState } from "react"
import styled, { withTheme } from "styled-components"
import Wrapper from "./wrapper";

const Section = styled.section`
  padding: 40px 0;

  @media screen and (min-width: 1024px) {
    padding: 64px 0;
  }
`

export const NotFoundTitle = styled.h4`
  margin: 0;
  font-size: 28px;
  font-weight: 300;
  text-align: center;
  padding: 0 0 40px 0;

  @media screen and (min-width: 1024px) {
    font-size: 32px;
    padding: 0 0 56px 0;
  }
`

const NotFound = () => (
  <Section id="not-found">
    <Wrapper>
      <NotFoundTitle>404: Page Not Found</NotFoundTitle>
    </Wrapper>
  </Section>
)

export default NotFound
