import React from "react"
import Layout from "../components/layout"
import NotFound from "../components/404";
import Banner from "../components/banner";


export default () => (
  <Layout>
    <Banner />
    <NotFound />
  </Layout>
)
